import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import './index.css'
import ProjectCard from "../components/ProjectCard"
import PageHead from "../components/PageHead"
import { Container } from 'react-bootstrap'

export default function Projects() {
  const projects = [
    {
      name: 'Docker runner',
      description: 'A node application that uses docker to run code remotely. Served using a REST API.',
      demo: '/DockerRunner'
    },

    {
      name: 'Mask AI',
      description: 'A Deep Learning model that can analyze how people wear masks.',
      demo: '/blog/maskai'
    },
    {
      name: 'Website',
      description: 'Website built using React and gatsby for my blog and projects',
      demo: '/'
    }
  ]
  const listProjects = projects.map(({ name, description, demo }) => (
    <ProjectCard name={name} description={description} demo={demo} />
  ))
  return (
    <Layout>
      <Seo title="Projects" />
      <PageHead title='Projects' description='Here are some of the projects I have worked on in the past' />
      <Container fluid  >

        {listProjects}
      </Container>
    </Layout>
  )
}
